export default function CleanButton(props: {
  onClick: () => void;
  className: string;
  children: React.ReactNode;
}) {
  const { onClick, className, children } = props;
  return (
    <button
      onClick={onClick}
      className={
        "transition px-4 py-2 rounded-md bg-blue-900 bg-opacity-70 hover:bg-opacity-90 active:bg-opacity-100 text-stone-100 font-bold font-mono " +
        className
      }
    >
      {children}
    </button>
  );
}
