import { startTransition, useState } from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import Image from "next/image";
import CleanButton from "@/components/CleanButton";

export default function Home() {
  const { user, error, isLoading } = useUser();

  return (
    <div className="h-full min-h-screen flex flex-col items-center justify-between bg-zinc-800">
      <div className="min-h-screen relative container mx-auto overflow-hidden">
        <Image
          src="/digiengineer_splash.png"
          alt="DigiEngineer"
          className="absolute inset-0 w-full h-full object-cover"
          layout="fill"
        />
        <div className="container absolute inset-0 w-full h-full md:bg-gradient-radial from-transparent to-zinc-800"></div>
        <div className="container absolute inset-0 w-full h-full sm:bg-gradient-to-r from-zinc-800 via-transparent to-zinc-800"></div>
        <div className="container absolute inset-0 w-full h-full bg-gradient-to-b from-zinc-800 via-transparent to-zinc-800"></div>
        <div className="container absolute inset-0 w-full h-full border-4 border-zinc-800 mx-auto"></div>
        <div className="container absolute inset-0 w-full h-full mx-auto">
          <div className="text-stone-100 flex flex-col gap-8 items-center p-24">
            <h1 className="text-4xl md:text-6xl font-bold font-mono">
              DigiEngineer
            </h1>
            <p className="text-center">
              Your AI-powered engineering intern. <br /> An intelligent task
              agent that works across your business tools and services like
              Slack and GitHub
            </p>
            <div>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-md h-10 w-10 border border-stone-100"></div>
                </div>
              ) : user ? (
                <div className="grid grid-cols-2 gap-4">
                  <CleanButton
                    onClick={() => {
                      if (user) {
                        startTransition(() => {
                          window.location.href = "/dashboard";
                        });
                      }
                    }}
                    className={"bg-blue-900"}
                  >
                    Dashboard
                  </CleanButton>
                  <CleanButton
                    onClick={() => {
                      window.location.href = "/api/auth/logout";
                    }}
                    className={"bg-stone-800"}
                  >
                    Log Out
                  </CleanButton>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  <CleanButton
                    onClick={() => {
                      window.location.href =
                        "/api/auth/signup?returnTo=/dashboard";
                    }}
                    className={"bg-blue-900"}
                  >
                    Try It Out
                  </CleanButton>
                  <CleanButton
                    onClick={() => {
                      window.location.href =
                        "/api/auth/login?returnTo=/dashboard";
                    }}
                    className={"bg-stone-800"}
                  >
                    Sign In
                  </CleanButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
